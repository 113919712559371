import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PosRestrictions } from '../../models/business/organization-pos/pos-restrictions.model';

export class OrganizationPosRestrictionsGetAction {
    static readonly type = '[OrganizationPosRestrictions Page] GetOrganizationPosRestrictions Get';

    constructor(public payload: { uid: string, areaUid: string, date: number, fixedDateFlag: boolean }) { }
}

export class OrganizationPosRestrictionsGetSuccessAction {
    static readonly type = '[OrganizationPosRestrictions API] GetOrganizationPosRestrictions Success';

    constructor(public payload: PosRestrictions[]) { }
}

export class OrganizationPosRestrictionsFailAction {
    static readonly type = '[OrganizationPosRestrictions API] GetOrganizationPosRestrictions Fail';

    constructor(public payload: ErrorObject) { }
}

export class OrganizationPosRestrictionsResetAction {
    static readonly type = '[OrganizationPosRestrictions Page] GetOrganizationPosRestrictions Reset';
}
