import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { OrganizationPosService } from '../../services/business/organization-pos.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PosRestrictions } from '../../models/business/organization-pos/pos-restrictions.model';

import { OrganizationPosRestrictionsFailAction, OrganizationPosRestrictionsGetAction, OrganizationPosRestrictionsGetSuccessAction, OrganizationPosRestrictionsResetAction } from './organization-pos-restrictions.actions';

export interface OrganizationPosRestrictionsStateModel {
    entity: PosRestrictions[];
    pending: boolean;
    error: ErrorObject;
}

@State<OrganizationPosRestrictionsStateModel>({
    name: 'posRestrictions',
    defaults: { pending: false, entity: null, error: null }
})
@Injectable()
export class OrganizationPosRestrictionsState {
    constructor(private orgPosService: OrganizationPosService) { }

    @Action(OrganizationPosRestrictionsGetAction) organizationPoseGet(ctx: StateContext<OrganizationPosRestrictionsStateModel>, action: OrganizationPosRestrictionsGetAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.orgPosService.getPosesRestrictionsByArea(action.payload.uid, action.payload.areaUid, action.payload.date, action.payload.fixedDateFlag)
            .then(resp => ctx.dispatch(new OrganizationPosRestrictionsGetSuccessAction(resp)))
            .catch(err => ctx.dispatch(new OrganizationPosRestrictionsFailAction(err)));
    }

    @Action(OrganizationPosRestrictionsGetSuccessAction) organizationPoseGetSuccess(ctx: StateContext<OrganizationPosRestrictionsStateModel>, action: OrganizationPosRestrictionsGetSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }

    @Action(OrganizationPosRestrictionsFailAction) organizationPoseGetFail(ctx: StateContext<OrganizationPosRestrictionsStateModel>, action: OrganizationPosRestrictionsFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload, });
    }

    @Action(OrganizationPosRestrictionsResetAction) organizationPoseGetReset(ctx: StateContext<OrganizationPosRestrictionsStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, error: null });
    }
}
