import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { OrganizationPosService } from '../../services/business/organization-pos.service';

import { PaginationStateBase, PaginationStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-state-base';

import { OrganizationPosesDetailedAction, OrganizationPosesDetailedFailAction, OrganizationPosesDetailedResetAction, OrganizationPosesDetailedSetFilterAction, OrganizationPosesDetailedSuccessAction, OrganizationPosesRestrictionsGetAction, OrganizationPosesRestrictionsSuccessAction } from './organization-poses-detailed.actions';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { OrganizationPosesListGetFilter, OrganizationPosesListGetFilterData } from '../../models/filters/organization-pos-list-get-filter.model';
import { OrganizationPosListItem } from '../../models/business/organization-pos/organization-pos-list-item.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PosRestrictions } from '../../models/business/organization-pos/pos-restrictions.model';

import { CompareUtils } from '../../../../burns-ui-framework/shared/utils/compare-utils';
import { DateUtils } from '../../../../burns-ui-framework/shared/utils/date-utils';
import { Utils } from '../../../../burns-ui-framework/shared/utils/utils';

import { ExgParamsConfig } from '../../exg-params.config';

export interface OrganizationPosesDetailedStateModel extends PaginationStateModel<OrganizationPosListItem> {
    uids: string[];
    entities: { [uid: string]: OrganizationPosListItem };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: OrganizationPosesListGetFilterData;
    total: number;

    restrictionsUids: string[];
    restrictions: { [uid: string]: PosRestrictions[] };
    restrictionDate: number;
}

@State<OrganizationPosesDetailedStateModel>({
    name: 'posesDetailed',
    defaults: {
        pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: { }, restrictionsUids: [], restrictions: {}, restrictionDate: DateUtils.currentDateTimeUtc
    }
})
@Injectable()
export class OrganizationPosesDetailedState extends PaginationStateBase {
    constructor(private orgPosService: OrganizationPosService) {
        super();
    }

    @Action([OrganizationPosesDetailedAction]) organizationPosesGet(ctx: StateContext<OrganizationPosesDetailedStateModel>, action: OrganizationPosesDetailedAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new OrganizationPosesListGetFilter({ pageIndex: state.index, pageSize: ExgParamsConfig.pagination.posesAllListPageSize, filterData: state.filter });
        if (!this.applyFilterPagination(action.payload, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.orgPosService.getPosList(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new OrganizationPosesDetailedSuccessAction({ list: resp, pagination: action.payload })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new OrganizationPosesDetailedFailAction(err)), 0));
    }

    @Action(OrganizationPosesDetailedSuccessAction) organizationPosesGetSuccess(ctx: StateContext<OrganizationPosesDetailedStateModel>, action: OrganizationPosesDetailedSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, total, index } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, ExgParamsConfig.pagination.posesAllListPageSize);
        ctx.setState({ ...state, pending: false, uids, entities, total, retrieved: true, error: null, allItemsLoaded, index });

        /// async load restrictions for new items
        const posUids = uids.filter(uid => !state.restrictionsUids.some(restrictionUid => restrictionUid === uid));
        if (!!posUids && posUids.length > 0) {
            setTimeout(() => ctx.dispatch(new OrganizationPosesRestrictionsGetAction({ posUids, date: state.restrictionDate })), 0);
        }
    }

    @Action(OrganizationPosesDetailedFailAction) organizationPosesGetFail(ctx: StateContext<OrganizationPosesDetailedStateModel>, action: OrganizationPosesDetailedFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(OrganizationPosesDetailedResetAction) organizationPosesGetReset(ctx: StateContext<OrganizationPosesDetailedStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, total: 0, index: 0, filter: {}, restrictionsUids: [], restrictions: {}, restrictionDate: DateUtils.currentDateTimeUtc });
    }

    @Action(OrganizationPosesDetailedSetFilterAction) usersSetFilter(ctx: StateContext<OrganizationPosesDetailedStateModel>, action: OrganizationPosesDetailedSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            ctx.setState({ ...state, filter, retrieved: false, index: 0 });
            setTimeout(() => ctx.dispatch(new OrganizationPosesDetailedAction(PaginationRequest.ReloadCurrentList)), 0);
        }
    }

    @Action(OrganizationPosesRestrictionsGetAction) organizationPosesRestrictionsGetReset(ctx: StateContext<OrganizationPosesDetailedStateModel>, action: OrganizationPosesRestrictionsGetAction) {
        const state = ctx.getState();
        if (!CompareUtils.isObjectsEqual(state.restrictionDate, action.payload.date)) {
            ctx.setState({ ...state, restrictionDate: action.payload.date });
        }

        return this.orgPosService.getPosesRestrictions(action.payload)
            .then(resp => setTimeout(() => ctx.dispatch(new OrganizationPosesRestrictionsSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new OrganizationPosesDetailedFailAction(err)), 0));
    }

    @Action(OrganizationPosesRestrictionsSuccessAction) organizationPosesRestrictionsSuccess(ctx: StateContext<OrganizationPosesDetailedStateModel>, action: OrganizationPosesRestrictionsSuccessAction) {
        const state = ctx.getState();
        const restrictions = { ...state.restrictions };
        const newUids = Utils.arrayUnique(action.payload.map(p => p.organizationPosUid));

        for (const uid of newUids) {
            restrictions[uid] = [...(restrictions[uid] || []), ...action.payload.filter(x => x.organizationPosUid === uid)];
        }

        const restrictionsUids = Utils.arrayUnique(state.restrictionsUids.concat(newUids));
        ctx.setState({ ...state, restrictions, restrictionsUids });
    }
}
