import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { IMenuItem } from '../../../burns-ui-framework/shared/components/common/exg-menu/exg-menu.component';

import { ActivityStatus } from '../../../evasys/shared/models/business/orders/order-activity-status.enum';
import { OrderListGetFilterData } from '../../../evasys/shared/models/filters/order-list-get-filter.model';
import { OrderSource } from '../../../evasys/shared/models/business/orders/order-source.enum';
import { OrderTime } from '../../../evasys/shared/models/business/orders/order-time.enum';
import { YeTopMenuEnum } from './ye-top-menu.enum';

import { DateUtils } from '../../../burns-ui-framework/shared/utils/date-utils';
import { Utils } from '../../../burns-ui-framework/shared/utils/utils';

@Component({
    selector: 'ye-top-menu',
    templateUrl: './ye-top-menu.component.html',
    styleUrls: ['./ye-top-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YeTopMenuComponent implements OnChanges {
    @Input() addBtnTitle = 'Add';
    @Input() hiddenMenuItems: YeTopMenuEnum[] = [];
    @Input() btnMenuStyleType: 'icon' | 'outlined' | 'texted' = 'texted';
    @Input() filter: OrderListGetFilterData;

    @Output() readonly menuClicked = new EventEmitter<YeTopMenuEnum>();
    @Output() readonly onAdd = new EventEmitter();
    @Output() readonly onOpenFilter = new EventEmitter();
    @Output() readonly onDeleteFilter = new EventEmitter<number>();

    public filterItems = [];
    public menuItems = [
        { id: YeTopMenuEnum.Download, title: 'Download', color: 'primary', icon: 'export' },
        { id: YeTopMenuEnum.Print, title: 'Print', color: 'primary', icon: 'print' },
        { id: YeTopMenuEnum.Send, title: 'Send', color: 'primary', icon: 'mail' },
        { id: YeTopMenuEnum.Delete, title: 'Delete', color: 'warn', icon: 'delete' },
    ].filter(menu => !this.hiddenMenuItems.includes(menu.id)) as IMenuItem[];

    private timeZone = DateUtils.timeZone;
    private timeItems = Utils.enumToSelectData(OrderTime);
    private activityItems = Utils.enumToSelectData(ActivityStatus);
    private sourceItems = Utils.enumToSelectData(OrderSource);

    public onMenuItemClick($event): void {
        this.menuClicked.emit($event.id);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.filter && this.filter) {
            this.filterItems.length = 0;
            this.generateFilterItems();
        }
    }

    public onAddButton(): void {
        this.onAdd.emit();
    }

    public openFilter(): void {
        this.onOpenFilter.emit();
    }

    public deleteFilter(index: number): void {
        this.onDeleteFilter.emit(index);
    }

    private determineTimeSelection(dateFrom: number, dateTo: number): OrderTime {
        if (dateFrom && dateTo) {
            const timezonedDateFrom = DateUtils.getEpocWithTimeZoneOffset(dateFrom, this.timeZone);
            const timezonedDateTo = DateUtils.getEpocWithTimeZoneOffset(dateTo, this.timeZone);
            const hoursDifference = (timezonedDateTo - timezonedDateFrom) / (3600 * 1000 * 2);
            return hoursDifference as OrderTime;
        }
        return null;
    }

    private generateFilterItems(): void {
        // time selection;
        const timeType = this.determineTimeSelection(this.filter.dateFrom, this.filter.dateTo);
        const timeSelection = timeType && this.timeItems.find(v => v.id === timeType)?.text;
        if (!!timeSelection) {
            this.filterItems.push({
                resetFilter: {
                    dateFrom: null, dateTo: null
                },
                title: timeSelection
            });
        }

        // filter date;
        const dateChanged = this.filter.dateFrom && this.filter.dateTo;
        if (!!dateChanged && !timeSelection) {
            this.filterItems.push({
                resetFilter: {
                    dateFrom: null,
                    dateTo: null
                },
                title: 'Date period'
            });
        }

        // activity selection;
        const activitySelection = this.filter.activityStatus !== null && this.activityItems.find(v => v.id === this.filter.activityStatus)?.text;
        if (!!activitySelection) {
            this.filterItems.push({
                resetFilter: {
                    activityStatus: null
                },
                title: activitySelection
            });
        }

        // source selection;
        const sourceSelection = this.filter.source && this.sourceItems.find(v => v.id === this.filter.source)?.text;
        if (!!sourceSelection) {
            this.filterItems.push({
                resetFilter: {
                    source: null
                },
                title: sourceSelection
            });
        }
    }
}
