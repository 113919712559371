import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { OrganizationPosService } from '../../services/business/organization-pos.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PosDetailed } from '../../models/business/organization-pos/pos-detailed.model';

import { OrganizationPosByCoordinatesFailAction, OrganizationPosByCoordinatesGetAction, OrganizationPosByCoordinatesGetSuccessAction, OrganizationPosByCoordinatesResetAction } from './organization-pos-by-coordinates.actions';

import { OrganizationPosRestrictionsGetSuccessAction } from './organization-pos-restrictions.actions';

export interface OrganizationPosByCoordinatesStateModel {
    entity: PosDetailed;
    pending: boolean;
    error: ErrorObject;
}

@State<OrganizationPosByCoordinatesStateModel>({
    name: 'posByCoordinates',
    defaults: { pending: false, entity: null, error: null }
})
@Injectable()
export class OrganizationPosByCoordinatesState {
    constructor(private orgPosService: OrganizationPosService) { }

    @Action(OrganizationPosByCoordinatesGetAction) organizationPoseGet(ctx: StateContext<OrganizationPosByCoordinatesStateModel>, action: OrganizationPosByCoordinatesGetAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.orgPosService.getPosByCoordinates(action.payload.longitude, action.payload.latitude, action.payload.date)
            .then(resp => ctx.dispatch(new OrganizationPosByCoordinatesGetSuccessAction(resp)))
            .catch(err => ctx.dispatch(new OrganizationPosByCoordinatesFailAction(err)));
    }

    @Action(OrganizationPosByCoordinatesGetSuccessAction) organizationPoseGetSuccess(ctx: StateContext<OrganizationPosByCoordinatesStateModel>, action: OrganizationPosByCoordinatesGetSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }

    @Action(OrganizationPosByCoordinatesFailAction) organizationPoseGetFail(ctx: StateContext<OrganizationPosByCoordinatesStateModel>, action: OrganizationPosByCoordinatesFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload, });
    }

    @Action(OrganizationPosByCoordinatesResetAction) organizationPoseGetReset(ctx: StateContext<OrganizationPosByCoordinatesStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, error: null });
    }

    @Action(OrganizationPosRestrictionsGetSuccessAction) organizationRestrictionGetSuccess(ctx: StateContext<OrganizationPosByCoordinatesStateModel>, action: OrganizationPosRestrictionsGetSuccessAction) {
        const state = ctx.getState();

        const entity = { ...state.entity, areaRestrictions: action.payload };
        ctx.setState({ ...state, pending: false, entity, error: null });
    }
}
